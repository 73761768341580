<template>
  <v-container fluid>
    <v-row align="center"
           no-gutters>
      <v-col class="flex-grow-0 text-no-wrap">
        <h1>{{$t('Unpaid bills')}}</h1>
      </v-col>
      <v-col>
        <v-btn color="error"
               class="ml-4"
               @click="exportToExcel">
          {{$t('Export to excel')}}
        </v-btn>
      </v-col>
    </v-row>
    <search-layout
      :loading="loading"
      single-line
      @search="search" />
    <trol-data-table ref="dataTable"
                     :data="data"
                     :totals="totals"
                     :columns="columns"
                     :page="props.page"
                     :sorting="props.sorting"
                     :storage-key="$options.name"
                     no-column-select
                     external-sorting
                     no-quick-filter
                     @changePage="changePage"
                     @sorting="sortingChanged">
      <template v-slot:cell-orderId="scope">
        <router-link v-for="requestId in scope.cell"
                     :key="requestId"
                     :to="{name: 'OrderProperties', params: {id: requestId}}"
                     class="mr-1">
          {{requestId}}
        </router-link>
      </template>
      <template v-slot:cell-billTotalAmount="scope">
        {{scope.cell}} {{scope.row.billCurrency.name}}
      </template>
      <template v-slot:cell-billRemainingBalance="scope">
        {{scope.cell}} {{scope.row.billCurrency.name}}
      </template>
      <template v-slot:cell-services="scope">
        <v-simple-table dense>
          <template v-slot>
            <template v-for="service in scope.cell">
              <thead :key="service.name+'_head'"
                     class="grey lighten-4">
                <tr>
                  <th class="text-left">
                    {{$t('Direction')}}
                  </th>
                  <th class="text-left">
                    {{$t('Service')}}
                  </th>
                </tr>
              </thead>
              <tbody :key="service.name+'_body'">
                <tr>
                  <td>{{service.route}}</td>
                  <td>{{service.name}}</td>
                </tr>
              </tbody>
            </template>
          </template>
        </v-simple-table>
      </template>
      <template v-slot:cell-overdueDate="scope">
        <v-sheet :class="(daysDiff(scope.cell) > 0 ? 'red' : 'grey') + ' fill-height caption' "
                 style="display: flex; align-items: center; justify-content: center;">
          <span class="white--text text-body-2 font-weight-medium">
            {{scope.cell | dateShort}} <br>
            <template v-if="daysDiff(scope.cell) > 0"> {{$t('Expiration')}} {{daysDiff(scope.cell) + ' ' + $t('days')}} </template>
          </span>
        </v-sheet>
      </template>
    </trol-data-table>
  </v-container>
</template>

<script>

import json2xlsx from 'json2xlsx-export';
import extend from 'lodash/extend';
import {TrolDataTable, SearchLayout} from '@/components/DataTable';
import {SearchState} from '@/services';

const propsDefaults = {
  sorting: {
    name: 'documentNumber',
    direction: 1,
  },
  page: 0,
  itemsPerPage: 50,
};

export default {
  name: 'PccBillList',
  components: {TrolDataTable, SearchLayout},
  data () {
    return {
      props: extend({}, propsDefaults),
      data: [],
      totals: {},
      loading: false,
      cancelingApi: this.API.getCancelingApi(),
      today: new Date(),
    };
  },
  computed: {
    columns () {
      return [
        {label: this.$t('Requests'), name: 'orderId', path: 'orderId'},
        {label: this.$t('Bill'), name: 'documentNumber', path: 'documentNumber', sortable: true},
        {label: this.$t('Company'), name: 'company', path: 'company.name', sortable: true},
        {label: this.$t('Sum'), name: 'billTotalAmount', path: 'billTotalAmount'},
        {label: this.$t('Await for payment'), name: 'billRemainingBalance', path: 'billRemainingBalance'},
        {label: this.$t('Services'), name: 'services', path: 'services'},
        {label: this.$t('Payment due'), name: 'overdueDate', path: 'overdueDate'},
      ];
    },
  },
  created () {
    this.props = SearchState.restore(this.$options.name, this.props);
  },
  methods: {
    async loadData () {
      try {
        this.loading  = true;
        const path    = ['printable-bills', 'not-paid'];
        const success = await this.cancelingApi.post.progress(path, this.props);
        SearchState.save(this.$options.name, this.props);
        this.data   = success.data.page;
        this.totals = success.data.totals;
      } finally {
        this.loading = false;
      }
    },
    search () {
      this.props.page = 0;
      this.loadData();
    },
    changePage (page) {
      this.props.page = page;
      this.loadData();
    },
    sortingChanged (sorting) {
      this.props.sorting = sorting;
      this.loadData();
    },
    daysDiff (dateStr) {
      const date = new Date(dateStr);

      return Math.floor((this.today.getTime() - date.getTime()) / (1000 * 3600 * 24));
    },
    exportToExcel () {
      const data = [
        [
          {
            type: 'string',
            value: 'Номер счета',
          },
          {
            type: 'string',
            value: 'Компания',
          },
          {
            type: 'string',
            value: 'Заявка',
          },
          {
            type: 'string',
            value: 'Стоимость услуг',
          },
          {
            type: 'string',
            value: 'Валюта',
          },
          {
            type: 'string',
            value: 'К оплате',
          },
          {
            type: 'string',
            value: 'Валюта',
          },
        ],
      ];
      this.data.forEach(bill => {
        data.push([
          {
            type: 'string',
            value: bill.documentNumber,
          },
          {
            type: 'string',
            value: bill.company.name,
          },
          {
            type: 'string',
            value: bill.orderId.slice(', '),
          },
          {
            type: 'number',
            value: bill.serviceTotalAmount,
          },
          {
            type: 'string',
            value: bill.billCurrency.name,
          },
          {
            type: 'number',
            value: bill.billRemainingBalance,
          },
          {
            type: 'string',
            value: bill.billCurrency.name,
          },
        ]);
      });

      const config = {
        filename: 'UnpaidBills',
        sheets: [
          {
            name: 'Список неоплаченных счетов',
            data: data,
          },
        ],
      };

      json2xlsx(config);
    },
  },

};
</script>
